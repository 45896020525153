<template>
  <div>
    <div class="page-container hide-list-menu">
      <div class="page-content">
        <!-- komponen header halaman-->
        <Header />
      </div>
    </div>
    <div class="container-fluid">
      <a href="#" v-on:click="kasir">Kasir</a> \ Riwayat Penjualan
      <div class="row py-5">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
          <div class="row" style="padding-bottom: 15px">
            <div class="col-lg-3">
              <input
                class="form-control"
                type="date"
                id="dateStart"
                name="dateStart"
                v-model="dateStart"
              />
            </div>
            <div class="col-lg-3">
              <input
                class="form-control"
                type="date"
                id="dateEnd"
                name="dateEnd"
                v-model="dateEnd"
              />
            </div>
            <div class="col-lg-3">
              <button
                class="btn btn-green btn-block"
                v-on:click="getRiwayatPenjualan"
              >
                Proses
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordereless">
                <tbody v-for="(row, i) in dataRiwayatPenjualan" v-bind:key="i">
                <tr style="background-color: lightgrey;">
                    <td class="text-left">{{ row.SaleDate }}</td>
                    <td colspan="2"></td>
                    <td class="text-right">Rp {{ $filters.numberTocurrencyIDR(row.TotalPerDate) }}</td>
                    <td></td>
                </tr>
                <tr v-for="(rox, j) in dataRiwayatPenjualan[row.SaleDate]" v-bind:key="j">
                    <td v-if="rox.DriverName" class="text-left" style="vertical-align: middle;">
                        Driver : {{ rox.DriverName }}
                    </td>
                    <td v-if="rox.DriverName == ''" class="text-left" style="vertical-align: middle;">
                        Pelanggan : {{ rox.CustomerName }}
                    </td>
                    <td v-if="rox.DriverName == ''" class="text-left"></td>
                    <td v-if="rox.DriverName" class="text-left" style="vertical-align: middle;">
                        Pemesan : {{ rox.CustomerName }} 
                    </td>
                    <td class="text-right" style="vertical-align: middle;">
                        Rp {{ $filters.numberTocurrencyIDR(rox.Total) }}
                    </td>
                    <td class="text-right">
                        {{ rox.SaleNumber }} <br/>{{ rox.SaleTime }}
                    </td>
                    <td style="text-align: center; width: 100px;">
                        <button
                            class="btn btn-link"
                            type="button"
                            @click="lihatRiwayatPenjualan(rox)" :disabled="submitted" >
                            Lihat
                        </button>
                        <button
                            style="display: none;"
                            class="btn btn-link"
                            type="button"
                            @click="cetakRiwayatPenjualan(rox)" :disabled="submitted" >
                            Cetak Ulang
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detail Riwayat Penjualan -->
    <DetailRiwayatPenjualan @cetakUlangRiwayatPenjualan="cetakUlangRiwayatPenjualan"/>
    <!-- Print Cetak Ulang Penjualan -->
    <PrintCetakUlangPenjualan ref="cetakUlangPenjualan" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import PrintCetakUlangPenjualan from "@/components/riwayat_penjualan/PrintCetakUlangPenjualan_c.vue";
import DetailRiwayatPenjualan from "@/components/riwayat_penjualan/DetailRiwayatPenjualan_c.vue";
import Header from "@/components/Header_c.vue";
export default {
  name: "RiwayatPenjualan",
  components: {
    Header,
    PrintCetakUlangPenjualan,
    DetailRiwayatPenjualan
  },
  data() {
    return {
      submitted: false,
      dateStart: null,
      dateEnd: null,
      dataRiwayatPenjualan: [],
    };
  },
  methods: {
    ...mapActions(["actGetRiwayatPenjualan", "actGetDetailRiwayatPenjualan"]),
    kasir() {
      this.$router.push({ name: "Kasir" });
    },
    setDateFilter() {
      var newDate = new Date();
      var tgl = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
      var bln =
        (newDate.getMonth() + 1 < 10 ? "0" : "") + (newDate.getMonth() + 1);
      var th = newDate.getFullYear();
      this.dateStart = th + "-" + bln + "-" + tgl;
      this.dateEnd = th + "-" + bln + "-" + tgl;
      this.getRiwayatPenjualan();
    },
    getRiwayatPenjualan() {
      var data = {
        dt1: this.dateStart,
        dt2: this.dateEnd,
      };
      this.$store.dispatch("actGetRiwayatPenjualan", data);
    },
    showRiwayatPenjualan() {
      var filterData = this.stRiwayatPenjualan.DaftarPenjualan;
      var riwayat = filterData.reduce((a, c) => {
        let filtered = a.filter((el) => el.SaleDate === c.SaleDate);
        if (filtered.length > 0) {
          a[a.indexOf(filtered[0])].Jumlah += 1;
          a[a.indexOf(filtered[0])].TotalPerDate += parseFloat(c.Total);
        } else {
          c.Jumlah = 1;
          c.TotalPerDate = parseFloat(c.Total);
          a.push(c);
        }
        return a;
      }, []);

      if (riwayat) {
        riwayat.sort((a, b) =>
          new Date(a.SaleDate) > new Date(b.SaleDate) ? -1 : new Date(b.SaleDate) > new Date(a.SaleDate) ? 1 : 0);
        this.dataRiwayatPenjualan = riwayat;
        for (let i = 0; i < riwayat.length; i++) {
          this.dataRiwayatPenjualan[riwayat[i].SaleDate] = filterData.filter(
            (sale) => {
              return sale.SaleDate == riwayat[i].SaleDate;
            }
          );
        }
      }
    },
    lihatRiwayatPenjualan(data){
        this.$store.dispatch("actGetDetailRiwayatPenjualan", data)
        .then((response) => { 
          if(response.status == "OK"){
            $("#modalDetailRiwayatPenjualan").modal("show");  
          }
        });
    },
    cetakRiwayatPenjualan(data){
        this.$store.dispatch("actGetDetailRiwayatPenjualan", data)
        .then((response) => { 
          if(response.status == "OK"){
            this.$refs.cetakUlangPenjualan.printCetakUlangPenjualan()  
          }
        });
    },
    cetakUlangRiwayatPenjualan() {
      this.$refs.cetakUlangPenjualan.printCetakUlangPenjualan()
    }
  },
  computed: {
    ...mapGetters(["stRiwayatPenjualan", "stDetailRiwayatPenjualan"]),
  },
  mounted() {
    this.setDateFilter();
  },
  watch: {
    stRiwayatPenjualan: function () {
      this.showRiwayatPenjualan();
    },
  },
};
</script>