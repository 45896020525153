<template>
  <div>
    <!-- start print receipt template small -->
    <div id="ticket-print-cetak-ulang" class="ticket" style="height: 100px; overflow: auto; display: none">
      <img v-if="getLogo" :src="getLogo" alt="Logo" class="center" style="width: 150px; height: auto" />
      <p class="centered">
        {{ options[0].CompanyName }} <br />
        {{ options[0].CompanyAddress }} <br />
        {{ options[0].CompanyPhone }}
      </p>
      <div style="width: 100%; border-top-style: dashed; border-bottom-style: dashed; " >
        <p style="text-align: center">THIS IS COPY</p>
      </div>
      <div style="width: 100%">
        <p style="margin-left: 0px; text-align: left" v-if="getPendingSale">
          Nomor Nota : {{ getPendingSale.SaleNumber }} <br />
          <span v-if="getPendingSale.NamaOpsiMakan">
            {{ getPendingSale.NamaOpsiMakan }} <br />
          </span>
          <span v-if="getPendingSale.CustomerName">
            Pelanggan : {{ getPendingSale.CustomerName }} <br />
          </span>
          <span v-if="getPendingSale.WaitressName">
            Pelayan : {{ getPendingSale.WaitressName }} <br />
          </span>
          Kasir : {{ getPendingSale.CreatedBy }} <br />
          Perangkat ke-{{ getPendingSale.DeviceNo }} <br />
          Tanggal : {{ getPendingSale.DateIndo }} {{ getPendingSale.SaleTime }} <br />
        </p>
      </div>
      <table style="margin-top: 20px" v-if="getPendingsaleitemdetail">
        <tbody v-for="(itemDetail, k) in getPendingsaleitemdetail" v-bind:key="k" >
          <tr>
            <td class="description" colspan="3">
              {{ itemDetail.ItemName }}
              <span v-if="itemDetail.Discount != ''">
                - Diskon
                <span v-if="itemDetail.Discount.indexOf('%') > 0">
                  {{ itemDetail.Discount }}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="quantity" colspan="2">
              &nbsp;
              {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }} X
              {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
              <span v-if="itemDetail.Discount != ''">
                -
                {{
                  $filters.numberTocurrencyIDR(itemDetail.Quantity * itemDetail.UnitPrice - itemDetail.SubTotal)
                }}
              </span>
            </td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
            </td>
          </tr>
          <tr v-for="(isiPaket, kk) in itemDetail.Details_Product" v-bind:key="kk">
            <td class="quantity" colspan="3">
              &nbsp;&nbsp;&nbsp;&nbsp; >>
              {{
                $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity)
              }}
              {{ isiPaket.ProductName }}
              {{ isiPaket.VariantName ? "- " + isiPaket.VariantName : "" }}
            </td>
          </tr>
          <tr v-if="itemDetail.Details_Product.length > 0">
            <td class="quantity" colspan="3">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <table style="margin-top: 20px" v-if="getPendingSale">
        <tbody>
          <tr>
            <td class="subtotal">
              <span v-if="getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount">
                Total Sebelum Diskon
              </span>
              <span v-else> Total </span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getSubtotal) }}
            </td>
          </tr>
          <tr v-for="(saleDisc, d) in getPendingsalediscountdetail" v-bind:key="d">
            <td class="subtotal">
              {{ saleDisc.DiscountName }}
              <span v-if="saleDisc.Discount.search('%') > 0">{{ saleDisc.Discount }}</span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }}
            </td>
          </tr>
          <tr v-if="getPendingsalediscountdetail.length == 0 && getPendingSale.FinalDiscount">
            <td class="subtotal">
              Diskon Final
              <span v-if="getPendingSale.FinalDiscount.toString().search('%') > 0">
                {{ getPendingSale.FinalDiscount }}
              </span>
            </td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getDiscountFinal) }}
            </td>
          </tr>
          <tr v-if="getPendingsaletaxexclude.length > 0 && (getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount)">
            <td class="subtotal">Total</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getBeforeTax) }}
            </td>
          </tr>
          <tr v-for="(saleTaxEx, t) in getPendingsaletaxexclude" v-bind:key="t">
            <td class="subtotal">{{ saleTaxEx.taxName }}</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(saleTaxEx.taxVal) }}
            </td>
          </tr>
          <tr v-if="getPendingSale.Rounding != 0">
            <td class="subtotal">Pembulatan</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getPendingSale.Rounding) }}
            </td>
          </tr>
          <tr v-if="getSubtotal != parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)">
            <td class="subtotal">Grand Total</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{
                $filters.numberTocurrencyIDR(parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding))
              }}
            </td>
          </tr>
          <tr v-if="charge && payment && !payment2">
            <td class="subtotal">Charge</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(charge) }}%
            </td>
          </tr>
          <tr v-if="paymentModeMsg1">
            <td class="subtotal">{{ paymentModeMsg1 }}</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(payment) }}
            </td>
          </tr>
          <tr v-if="charge && payment2">
            <td class="subtotal">Charge</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(charge) }}%
            </td>
          </tr>
          <tr v-if="paymentModeMsg2">
            <td class="subtotal">{{ paymentModeMsg2 }}</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(payment2) }}
            </td>
          </tr>
          <tr v-if="getPendingSale.Change">
            <td class="subtotal">Kembali</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(getPendingSale.Change) }}
            </td>
          </tr>
          <tr v-if="pendingsaletaxinclude.length > 0">
            <td class="subtotal">Sudah Termasuk</td>
            <td class="quantity">:</td>
            <td class="subtotal"></td>
          </tr>
          <tr v-for="(saleTaxIn, t) in pendingsaletaxinclude" v-bind:key="t">
            <td class="subtotal">{{ saleTaxIn.taxName }}</td>
            <td class="quantity">:</td>
            <td class="subtotal">
              {{ $filters.numberTocurrencyIDR(saleTaxIn.taxVal) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="centered">
        <span v-if="options[0].FootNoteSatu">
          {{ options[0].FootNoteSatu }} <br />
        </span>
        <span v-if="options[0].FootNoteDua">
          {{ options[0].FootNoteDua }} <br />
        </span>
        <span v-if="options[0].FootNoteTiga">
          {{ options[0].FootNoteTiga }} <br />
        </span>
        <span v-if="options[0].FootNoteEmpat">
          {{ options[0].FootNoteEmpat }} <br />
        </span>
        powered by nutapos.com
      </p>
    </div>
    <!-- end print receipt template small -->

    <!-- start print receipt template large -->
    <div id="ticket-print-cetak-ulang-lg" style="height: 100px; overflow: auto; display: none">
      <table width="100%" v-if="getLogo">
        <tr>
          <td style="width: 20%; text-align: center">
            <img v-if="getLogo" :src="getLogo" alt="Logo" style="width: 120px; height: auto"/>
          </td>
          <td style="width: 60%; text-align: left">
            <span style="font-size: 19px">{{ options[0].CompanyName }}</span><br />
            <span style="font-size: 15px">{{ options[0].CompanyAddress }}</span><br />
            <span style="font-size: 15px">{{ options[0].CompanyPhone }}</span>
          </td>
          <td style="width: 20%"></td>
        </tr>
      </table>
      <table width="100%" v-else>
        <tr>
          <td style="text-align: left">
            <span style="font-size: 19px">{{ options[0].CompanyName }}</span><br />
            <span style="font-size: 15px">{{ options[0].CompanyAddress }}</span><br />
            <span style="font-size: 15px">{{ options[0].CompanyPhone }}</span>
          </td>
        </tr>
      </table>
      <div style="width: 100%; font-size: 15px" v-if="getPendingSale">
        <hr />
        <div style="width: 55%; float: left">
          <table>
            <tr>
              <td>Nomor Nota</td>
              <td>:</td>
              <td>{{ getPendingSale.SaleNumber }}</td>
            </tr>
            <tr>
              <td>Pelayan</td>
              <td>:</td>
              <td>{{ getPendingSale.WaitressName }}</td>
            </tr>
            <tr>
              <td>Kasir</td>
              <td>:</td>
              <td>{{ getPendingSale.CreatedBy }}</td>
            </tr>
          </table>
        </div>
        <div style="width: 45%; float: left">
          <table>
            <tr>
              <td>Tipe Penjualan</td>
              <td>:</td>
              <td>{{ getPendingSale.NamaOpsiMakan }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td>:</td>
              <td>
                {{ getPendingSale.DateIndo }} {{ getPendingSale.SaleTime }}
              </td>
            </tr>
            <tr>
              <td>Pelanggan</td>
              <td>:</td>
              <td>{{ getPendingSale.CustomerName }}</td>
            </tr>
          </table>
        </div>
      </div>
      <table width="100%" style="border-collapse: collapse" v-if="getPendingsaleitemdetail">
        <tr>
          <th style="border: 1px solid black; padding: 5px">NO</th>
          <th style="border: 1px solid black; padding: 5px">NAMA ITEM</th>
          <th style="border: 1px solid black; padding: 5px">QTY</th>
          <th style="border: 1px solid black; padding: 5px">HARGA SATUAN</th>
          <th style="border: 1px solid black; padding: 5px">DISKON</th>
          <th style="border: 1px solid black; padding: 5px">SUB TOTAL</th>
        </tr>
        <tr v-for="(itemDetail, k) in getPendingsaleitemdetail" v-bind:key="k">
          <td style="border: 1px solid black; text-align: center; padding: 3px">
            {{ k + 1 }}
          </td>
          <td style="border: 1px solid black; text-align: left; padding: 3px">
            {{ itemDetail.ItemName }} <br />
            <div class="print-isipaket" v-for="(isiPaket, kk) in itemDetail.Details_Product" v-bind:key="kk">
              <span style="padding-left: 30px">
                -
                {{
                  $filters.numberTocurrencyIDR(isiPaket.QtyUsed * itemDetail.Quantity)
                }}
                {{ isiPaket.ProductName }}
                {{
                  isiPaket.VariantName ? "- " + isiPaket.VariantName : ""
                }} </span
              ><br />
            </div>
          </td>
          <td style="border: 1px solid black; text-align: center; padding: 3px">
            {{ $filters.numberTocurrencyIDR(itemDetail.Quantity) }}
          </td>
          <td style="border: 1px solid black; text-align: right; padding: 3px">
            {{ $filters.numberTocurrencyIDR(itemDetail.UnitPrice) }}
          </td>
          <td style="border: 1px solid black; text-align: right; padding: 3px">
            {{ itemDetail.Discount }}
          </td>
          <td style="border: 1px solid black; text-align: right; padding: 3px">
            {{ $filters.numberTocurrencyIDR(itemDetail.SubTotal) }}
          </td>
        </tr>
      </table>
      <table width="100%" v-if="getPendingSale">
        <tr>
          <td style="width: 60%"></td>
          <td>
            <span v-if="getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount">
              Total Sebelum Diskon
            </span>
            <span v-else> Total </span>
          </td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(getSubtotal) }}
          </td>
        </tr>
        <tr v-for="(saleDisc, d) in getPendingsalediscountdetail" v-bind:key="d">
          <td style="width: 60%"></td>
          <td>
            {{ saleDisc.DiscountName }}
            <span v-if="saleDisc.Discount.search('%') > 0">
              {{ saleDisc.Discount }}
            </span>
          </td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(saleDisc.DiscountValue) }}
          </td>
        </tr>
        <tr v-if="getPendingsalediscountdetail.length == 0 && getPendingSale.FinalDiscount">
          <td style="width: 60%"></td>
          <td>
            Diskon Final
            <span v-if="getPendingSale.FinalDiscount.toString().search('%') > 0">
              {{ getPendingSale.FinalDiscount }}
            </span>
          </td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(getDiscountFinal) }}
          </td>
        </tr>
        <tr v-if="getPendingsaletaxexclude.length > 0 && (getPendingsalediscountdetail.length > 0 || getPendingSale.FinalDiscount)">
          <td style="width: 60%"></td>
          <td>Total</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(getBeforeTax) }}
          </td>
        </tr>
        <tr v-for="(saleTaxEx, t) in getPendingsaletaxexclude" v-bind:key="t">
          <td style="width: 60%"></td>
          <td>{{ saleTaxEx.taxName }}</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(saleTaxEx.taxVal) }}
          </td>
        </tr>
        <tr v-if="getPendingSale.Rounding">
          <td style="width: 60%"></td>
          <td>Pembulatan</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(getPendingSale.Rounding) }}
          </td>
        </tr>
        <tr v-if="getSubtotal != parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding)">
          <td style="width: 60%"></td>
          <td>Grand Total</td>
          <td></td>
          <td style="text-align: right">
            {{
              $filters.numberTocurrencyIDR(parseFloat(getPendingSale.Total) + parseFloat(getPendingSale.Rounding))
            }}
          </td>
        </tr>
        <tr v-if="charge && payment && !payment2">
          <td style="width: 60%"></td>
          <td>Charge</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(charge) }}%
          </td>
        </tr>
        <tr v-if="paymentModeMsg1">
          <td style="width: 60%"></td>
          <td>{{ paymentModeMsg1 }}</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(payment) }}
          </td>
        </tr>
        <tr v-if="charge && payment2">
          <td style="width: 60%"></td>
          <td>Charge</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(charge) }}%
          </td>
        </tr>
        <tr v-if="paymentModeMsg2">
          <td style="width: 60%"></td>
          <td>{{ paymentModeMsg2 }}</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(payment2) }}
          </td>
        </tr>
        <tr v-if="getPendingSale.Change">
          <td style="width: 60%"></td>
          <td>Kembali</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(getPendingSale.Change) }}
          </td>
        </tr>
        <tr v-if="pendingsaletaxinclude.length > 0">
          <td style="width: 60%"></td>
          <td>Sudah Termasuk</td>
          <td></td>
          <td style="text-align: right"></td>
        </tr>
        <tr v-for="(saleTaxIn, t) in pendingsaletaxinclude" v-bind:key="t">
          <td style="width: 60%"></td>
          <td>{{ saleTaxIn.name }}</td>
          <td></td>
          <td style="text-align: right">
            {{ $filters.numberTocurrencyIDR(saleTaxIn.val) }}
          </td>
        </tr>
      </table>
      <table width="100%" style="padding-top: 5px; padding-bottom: 20px">
        <tr>
          <td style="text-align: center">Penerima,</td>
          <td style="text-align: center">Pengirim,</td>
          <td style="text-align: center">Hormat Kami,</td>
        </tr>
        <tr>
          <td style="text-align: center" colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td style="text-align: center" colspan="3">&nbsp;</td>
        </tr>
        <tr>
          <td style="text-align: center">(_____________)</td>
          <td style="text-align: center">(_____________)</td>
          <td style="text-align: center">(_____________)</td>
        </tr>
      </table>
      <table width="100%">
        <tr>
          <td style="text-align: left">
            {{ options[0].FootNoteSatu }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left">
            {{ options[0].FootNoteDua }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left">
            {{ options[0].FootNoteTiga }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left">
            {{ options[0].FootNoteEmpat }}
          </td>
        </tr>
        <tr>
          <td style="text-align: left">powered by nutapos.com</td>
        </tr>
      </table>
    </div>
    <!-- end print receipt template large -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  name: "PrintCetakUlangPenjualan_c",
  components: {},
  data() {
    return {
      paymentModeMsg1: null,
      paymentModeMsg2: null,
      payment: 0,
      payment2: 0,
    };
  },
  props: {
    dataTransactionID: String,
  },
  computed: {
    ...mapGetters([
      "options",
      "stDetailRiwayatPenjualan",
      "wsBaseUrl",
      "masterdigitalpayment",
    ]),
    //data logo
    getLogo() {
      var logoReceipt = null;
      var tempDataOptions = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
      tempDataOptions = tempDataOptions.filter((opt) => {
        return opt.OnlineImagePath != "";
      });
      if (tempDataOptions.length > 0) {
        if (tempDataOptions[0].OnlineImagePath.substring(-1, 4) == "http") {
          logoReceipt = tempDataOptions[0].OnlineImagePath;
        } else {
          logoReceipt = this.wsBaseUrl + tempDataOptions[0].OnlineImagePath;
        }
      } else {
        var tempLocalImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
        tempLocalImage = tempLocalImage.filter((loc) => {
          return loc.Table == "options" && loc.TableID == "1";
        });
        if (tempLocalImage.length > 0) {
          logoReceipt = tempLocalImage[0].Image;
        }
      }
      return logoReceipt;
    },
    //data penjualan
    getPendingSale() {
      var data = $.isEmptyObject(this.stDetailRiwayatPenjualan) == false ? this.stDetailRiwayatPenjualan : {};
      if ($.isEmptyObject(data) == false) {
        data.DateIndo = this.convertDateDBtoIndo(data.SaleDate);
        this.paymentMethod(data);
      }

      return data;
    },
    //data item detail
    getPendingsaleitemdetail() {
      var data = $.isEmptyObject(this.getPendingSale) == false ? this.getPendingSale.Details_Item : [];
      return data;
    },
    //perhitungan sub total
    getSubtotal() {
      var subTotal = 0;
      var data = $.isEmptyObject(this.getPendingSale) == false ? this.getPendingSale.Details_Item : [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          subTotal += parseFloat(data[i].SubTotal);
        }
      }
      return subTotal;
    },
    //perhitungan diskon
    getPendingsalediscountdetail() {
      var data = $.isEmptyObject(this.getPendingSale) == false ? this.getPendingSale.Detail_Discount : [];

      return data;
    },
    //perhitungan diskon final
    getDiscountFinal() {
      let discFinal = 0;
      if (this.getPendingsalediscountdetail.length == 0) {
        if ($.isEmptyObject(this.getPendingSale) == false) {
          var dataDics = this.getPendingSale.FinalDiscount.toString();
          if (dataDics.search("%") > 0) {
            discFinal =
              (this.getSubtotal / 100) *
              parseFloat(this.getPendingSale.FinalDiscount.replace("%", ""));
          } else {
            discFinal = parseFloat(this.getPendingSale.FinalDiscount);
          }
        }
      }
      return discFinal;
    },
    //perhitungan tidak termasuk pajak
    getPendingsaletaxexclude() {
      var arr = [];
      if ($.isEmptyObject(this.getPendingSale) == false) {
        var explode = this.getPendingSale.DetailExcludeTaxValues.split("#");
        if (explode) {
          for (let i = 0; i < explode.length; i++) {
            if (explode[i]) {
              var explode2 = explode[i].split("$");
              var taxexarr = {
                taxName: explode2[0],
                taxVal: parseFloat(explode2[1]),
              };
              arr.push(taxexarr);
            }
          }
        }
      }

      return arr;
    },
    //perhitungan termasuk pajak
    pendingsaletaxinclude() {
      var arr = [];
      if ($.isEmptyObject(this.getPendingSale) == false) {
        var explode = this.getPendingSale.DetailIncludeTaxValues.split("#");
        if (explode) {
          for (let i = 0; i < explode.length; i++) {
            if (explode[i]) {
              var explode2 = explode[i].split("$");
              var taxexarr = {
                taxName: explode2[0],
                taxVal: parseFloat(explode2[1]),
              };
              arr.push(taxexarr);
            }
          }
        }
      }

      return arr;
    },
    //perhitungan sebelum pajak
    getBeforeTax() {
      let beforeTax = 0;
      var tot = this.getSubtotal;
      if (this.getPendingsalediscountdetail.length > 0) {
        this.getPendingsalediscountdetail.forEach(function (discOutlet) {
          if (discOutlet.Discount.search("%") > 0) {
            tot = parseFloat(
              tot - (tot / 100) * discOutlet.Discount.replace("%", "")
            );
          } else {
            tot = parseFloat(tot - discOutlet.Discount);
          }
        });
      }
      beforeTax = tot - this.getDiscountFinal;
      return beforeTax;
    },
    //perhitungan charge
    charge() {
      var charge = 0;
      if ($.isEmptyObject(this.getPendingSale) == false) {
        var a = parseFloat(this.getPendingSale.CashDownPayment) + parseFloat(this.getPendingSale.BankDownPayment) + parseFloat(this.getPendingSale.CashPaymentAmount);
        var b = parseFloat(this.getPendingSale.Total + this.getPendingSale.Rounding) - a;
        var c = parseFloat(this.getPendingSale.BankPaymentAmount) - (parseFloat(this.getPendingSale.Total + this.getPendingSale.Rounding) - a);
        var d = (c / b) * 100;
        if (d > 0 && b > 0) {
          charge = d;
        } else {
          charge = 0;
        }
      }
      return charge;
    },
  },
  methods: {
    // cetak struk
    printCetakUlangPenjualan() {
      var templateData = localStorage.getItem("receipttemplate") ? JSON.parse(localStorage.getItem("receipttemplate")) : [];
      if (templateData.length > 0) {
        if (templateData[0].template == "small") {
          this.printCetakUlangPenjualanSm();
        } else if (templateData[0].template == "big") {
          this.printCetakUlangPenjualanLg();
        }
      } else {
        this.printCetakUlangPenjualanSm();
      }
    },
    // cetak struk small
    printCetakUlangPenjualanSm() {
      // Get HTML to print from element
      const prtHtml = document.getElementById(
        "ticket-print-cetak-ulang"
      ).innerHTML;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          table {
            /*border: 1px solid black;*/
            border-collapse: collapse;
            width: 100%;
          }

          td.description {
            text-align: left;
          }

          td.quantity {
            text-align: left;
          }

          td.subtotal {
            text-align: right;
          }

          .centered {
            text-align: center;
            align-content: center;
          }

          .ticket {
            width: 280px;
            max-width: 280px;
          }

          img {
            max-width: inherit;
            width: inherit;
          }
          .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 110px;
            width: auto;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      setTimeout(() => WinPrint.close(), 1000);

      return true;
    },
    // cetak struk big
    printCetakUlangPenjualanLg() {
      const prtHtml = document.getElementById(
        "ticket-print-cetak-ulang-lg"
      ).innerHTML;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <style>
          body {
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          </style>
          </head>
          <body style="background-color:white;">
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
      setTimeout(() => WinPrint.close(), 1000);

      return true;
    },
    // format tanggal
    convertDateDBtoIndo(string) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];

      var tanggal = string.split("-")[2];
      var bulan = string.split("-")[1];
      var tahun = string.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    // metode pembayaran
    paymentMethod(data) {
      var paymentMode = data.PaymentMode.toString();
      if (paymentMode.substring(0, 1) == "1") {
        this.paymentModeMsg1 = "Bayar Tunai";
        this.changeMsg = data.Change ? data.Change : "0";
        this.payment = data.CashPaymentAmount;
      } else if (paymentMode.substring(0, 1) == "2") {
        this.payment = data.BankPaymentAmount;
        this.bankMsg = data.CashBankAccountName.replaceAll("#", " ");
        if (paymentMode.substring(0, 2) == "21") {
          var dgPayment = this.masterdigitalpayment.find(
            (b) => b.DigitalPaymentID == paymentMode.substring(2, 4)
          );
          this.paymentModeMsg1 = "Bayar Dompet Digital - " + dgPayment.QRName;
        } else if (paymentMode == "22") {
          this.paymentModeMsg1 = "Bayar EDC";
        } else if (paymentMode == "23") {
          this.paymentModeMsg1 = "Bayar Transfer";
        } else if (paymentMode == "24") {
          this.paymentModeMsg1 = "Bayar QR Statis";
        }
      } else if (paymentMode.substring(0, 1) == "3") {
        this.payment = data.CashPaymentAmount;
        this.payment2 = data.BankPaymentAmount;
        this.paymentModeMsg1 = "Bayar Tunai";
        if (paymentMode.substring(0, 2) == "31") {
          this.paymentModeMsg2 = "Bayar Dompet Digital";
        } else if (paymentMode == "32") {
          this.paymentModeMsg2 = "Bayar EDC";
        } else if (paymentMode == "33") {
          this.paymentModeMsg2 = "Bayar Transfer";
        } else if (paymentMode == "34") {
          this.paymentModeMsg2 = "Bayar QR Statis";
        }
      }
    },
  },
};
</script>
<style scoped>
table {
  /*border: 1px solid black;*/
  border-collapse: collapse;
  width: 100%;
}

td.description {
  text-align: left;
}

td.quantity {
  text-align: left;
}

td.subtotal {
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 280px;
  max-width: 280px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 110px;
  width: auto;
}

@media print {
}
</style>